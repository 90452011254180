<template>
  <div class="reserve-form-wrap">
    <!-- 예약입력  -->
    <div class="reserve-form-wrap--info">
      <div class="reserve-form-wrap--top">
        <p class="reserve-form-wrap--title"><strong>휴대전화 번호</strong>를<br>입력해주세요.</p>
        <p class="reserve-form-wrap--text">
          방문예약하신 고객님은<br/>
          <strong>예약하신 휴대전화번호로 방문등록</strong>을 하고<br/>
          안내에 따라 입장하여 주시기 바랍니다.
        </p>
      </div>
      <div class="reserve-form-wrap--sub">
        <div class="reserve-form-wrap--sub-kakao">
          방문등록하고 <strong>카카오톡</strong>으로 확인하세요
        </div>
      </div>

    </div>

    <!-- 약관 동의 -->
    <div class="reserve-form-wrap--terms">
      <p class="reserve-form-wrap--terms-txt">견본주택 입장을 위한<br><strong>방문등록을 해주세요.</strong></p>
      <p class="reserve-form-wrap--terms-subtxt">방문등록 및 간단한 설문진행 후 안내에 따라<br>입장하여 주시기 바랍니다.</p>
      <div class="reserve-form-wrap--terms-chk">
        <strong class="reserve-form-wrap--terms-chk--title">{{ TERMS_TITLE }}</strong>
        <div  class="reserve-form-wrap--terms-chk--all">
          <v-checkbox
          class="type-round"
          :on-icon="'svg-chk2-on'"
          :off-icon="'svg-chk2-off'"
          label="모든 약관에 동의"></v-checkbox>
        </div>
        <div  class="reserve-form-wrap--terms-chk--sub">
          <v-checkbox
          class="type-round"
          :on-icon="'svg-chk2-on'"
          :off-icon="'svg-chk2-off'"
          label="개인 정보 수집및 이용동의"></v-checkbox>
          <v-checkbox
          class="type-round"
          :on-icon="'svg-chk2-on'"
          :off-icon="'svg-chk2-off'"
          label="마케팅 활용 동의"></v-checkbox>
        </div>
      </div>
      <div class="reserve-form-wrap--terms-btn">
        <button type="button" class="btn-keypad-del"><span class="hide">del</span></button>
        <button type="button" class="btn-keypad-in">입력</button>
      </div>

    </div>
  </div>
  </template>

  <script>
    export default {
      name: 'CAP0560',
      components: {},
      props: {
      },
      data() {
        return {
          TERMS_TITLE: '더샵 거창포르시엘2차 방문등록',

        }
      },
      mounted() {},
      computed: {},
      methods: {

      }
    }
  </script>

  <style>
  </style>